// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/R-FLEX/R-FLEX-BOLD.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/R-FLEX/R-FLEX-BOLDITALIC.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/R-FLEX/R-FLEX-REGULAR.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/R-FLEX/R-FLEX-REGULARITALIC.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: bold;\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: bold;\n  font-style: italic;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: normal;\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: normal;\n  font-style: italic;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"opentype\");\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/font-r-flfx.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,+DAA8D;AAChE;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,+DAAoE;AACtE;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,+DAAiE;AACnE;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,+DAAuE;AACzE","sourcesContent":["@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: bold;\n  font-style: normal;\n  src: url(\"../fonts/R-FLEX/R-FLEX-BOLD.otf\") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: bold;\n  font-style: italic;\n  src: url(\"../fonts/R-FLEX/R-FLEX-BOLDITALIC.otf\") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: normal;\n  font-style: normal;\n  src: url(\"../fonts/R-FLEX/R-FLEX-REGULAR.otf\") format(\"opentype\");\n}\n@font-face {\n  font-family: \"R-FLEX\";\n  font-weight: normal;\n  font-style: italic;\n  src: url(\"../fonts/R-FLEX/R-FLEX-REGULARITALIC.otf\") format(\"opentype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
